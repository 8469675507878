import { render, staticRenderFns } from "./FinancialMovementCreate.vue?vue&type=template&id=dcd12a76&scoped=true"
import script from "./FinancialMovementCreate.vue?vue&type=script&lang=js"
export * from "./FinancialMovementCreate.vue?vue&type=script&lang=js"
import style0 from "./FinancialMovementCreate.vue?vue&type=style&index=0&id=dcd12a76&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dcd12a76",
  null
  
)

export default component.exports