<template>
  <div class="pa-4">
    <v-row>
      <v-col cols="12" sm="3">
        <div class="text-h5 text-sm-h4">Extratos</div>
        <v-spacer></v-spacer>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="3">
        <v-card :loading="loadingFA||loadingFBSP" style="z-index: 0;" class="pa-1">
          
            <v-card-title>
              {{ (this.accountSelected=='') ? 'Lista de contas financeiras' : this.accountSelected }}
              <v-spacer></v-spacer>
              <v-icon v-if="this.showAccounts" @click="changeShowAccount(false)">mdi-arrow-down-drop-circle-outline</v-icon>
              <v-icon v-if="!this.showAccounts" @click="changeShowAccount(true)">mdi-arrow-up-drop-circle-outline</v-icon>
            </v-card-title>
            
            <v-list-item-group
              v-model="positionFA"
              color="primary"
              v-if="this.showAccounts"
            >
              <v-list-item
                v-for="(item, i) in this.listFinancialAccount"
                :key="item.id"
                @click="callListFinancialBankStatementProcess(item)"
              >
                <v-list-item-icon >
                  <v-icon v-if="item.type=='AC'">mdi-bank-transfer</v-icon>
                  <v-icon v-if="item.type=='SA'">mdi-piggy-bank</v-icon>
                  <v-icon v-if="item.type=='CC'">mdi-credit-card-outline</v-icon>
                  <v-icon v-if="item.type=='PP'">mdi-credit-card-plus</v-icon>
                  <v-icon v-if="item.type=='MO'">mdi-cash</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>

            <v-divider v-if="!this.showAccounts"></v-divider>

            <v-card-title v-if="!this.showAccounts">
              Referências
            </v-card-title>
            
            <v-list-item-group
              v-if="!this.showAccounts"
              v-model="positionFBSP"
              color="primary"
            >
              <v-list-item
                v-for="(item, i) in this.listFinancialBankStatementProcess"
                :key="item.id"
                @click="callList(item), callMovement(item)"
                :disabled="item.processed!='Sim'"
              >
                <!-- <v-list-item-icon >
                  <v-icon v-if="item.type=='AC'">mdi-bank-transfer</v-icon>
                  <v-icon v-if="item.type=='SA'">mdi-piggy-bank</v-icon>
                  <v-icon v-if="item.type=='CC'">mdi-credit-card-outline</v-icon>
                  <v-icon v-if="item.type=='PP'">mdi-credit-card-plus</v-icon>
                  <v-icon v-if="item.type=='MO'">mdi-cash</v-icon>
                </v-list-item-icon> -->
                <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>
                  <v-list-item-subtitle v-if="item.processed=='Sim'" v-text="formatDate(item.date_start) +' - '+ formatDate(item.date_end) "></v-list-item-subtitle>
                  <v-list-item-subtitle v-if="item.processed!='Sim'" v-text="'Arquivo não processado'"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>

            <v-divider v-if="!this.showAccounts"></v-divider>

            <v-card-title v-show="!this.showAccounts && this.listFinancialBankStatement.length > 0">
              Datas
              <v-spacer></v-spacer>
              {{ formatDate(this.dateFilter) }}
            </v-card-title>
            
            <!-- <v-list-item-group
              v-model="positionPD"
              color="primary"
            >
              <v-list-item
                v-for="(item, i) in this.possibleDates"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="item"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group> -->

            <v-date-picker 
              v-show="!this.showAccounts && this.listFinancialBankStatement.length > 0"
              full-width 
              no-title 
              locale="pt-br" 
              v-model="dateFilter"
              @click:date="changeDate" style="border:1px solid #f2f2f2"
              :min="this.financialBankStatementProcess.date_start"
              :max="this.financialBankStatementProcess.date_end"
              >
            </v-date-picker>

        </v-card>
      </v-col>

      <v-col cols="12" sm="9">
        <v-card :loading="loadingFBS" style="z-index: 0" class="pa-1">
          <div>
            <v-row>

              <v-col cols="12" sm="6">
                
                <v-card-title>
                  Extrato
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="!loadingFBS"
                    class="mr-2"
                    type="button"
                    :color="(details)?'blue':'gray'"
                    icon
                    @click="toggleDetails()"
                  >
                    <v-icon>mdi-information-outline</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="!loadingFBS"
                    class="mr-2"
                    type="button"
                    :color="(filterCheck)?'blue':'gray'"
                    icon
                    @click="toggleFilterCheck()"
                  >
                    <v-icon>mdi-check-circle-outline</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="!loadingFBS"
                    class="mr-2"
                    type="button"
                    color="blue"
                    icon
                    @click="toggleSearch()"
                    :color="(showSearch)?'blue':'gray'"
                  >
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="!loadingFBS"
                    class="mr-2"
                    type="button"
                    color="blue"
                    icon
                    @click="refreshData()"
                  >
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                  <!-- <v-btn
                    class="mr-2"
                    type="button"
                    color="blue"
                    icon
                    @click="openFinancialBankStatementForm()"
                  >
                    <v-icon>mdi-plus-circle</v-icon>
                  </v-btn> -->
                </v-card-title>
    
                <div
                  class="d-flex justify-space-between align-center"
                  v-if="showSearch"
                >
                  <v-col cols="9">
                    <v-text-field
                      v-model="searchFinancialBankStatement"
                      append-icon="mdi-magnify"
                      label="Pesquisar"
                      dense
                      single-line
                      autofocus
                      hide-details
                    />
                  </v-col>
    
                  <v-col cols="3" class="px-4">
                    <v-row justify="end" class="mr-2">
                      <v-btn @click="clearSearchFBS()">
                        <v-icon color="grey"> mdi-broom </v-icon>
                        Limpar
                      </v-btn>
                      <!-- <v-btn icon @click="toggleSearch()">
                        <v-icon color="grey"> mdi-close </v-icon>
                      </v-btn> -->
                    </v-row>
                  </v-col>
                </div>
                
                <v-list-item-group
                  v-model="positionFBS"
                  color="primary"
                >
                  <v-list-item
                    v-for="(item, i) in this.filterFBS"
                    :key="item.id"
                    three-line
                    @click="getFinancialBankStatement(item)"
                    v-if="filterCheck || (!filterCheck && item.included.movement_id==null)"
                  >   
                      <!-- icone -->
                      <v-list-item-icon style="margin-right: 6px;">
                        <v-icon v-if="item.included.movement_id==null" small class="mr-2">
                          mdi-clock-outline
                        </v-icon>  
                        <v-icon v-if="item.included.movement_id!=null" small class="mr-2" @click="conectMovement(item)">
                          mdi-check-bold
                        </v-icon>
                      </v-list-item-icon>
                      <!-- conteudo -->
                      <v-list-item-content style="border-bottom: 1px dotted #ccc; align-content:flex-start;" :class=" details ? 'details-on' : 'details-off'">
                        <v-list-item-title style="height:20px; margin: 4px 0px 12px 0px;" v-text="formatDate(item.date_movement)"></v-list-item-title>
                        <v-list-item-subtitle v-text="getInfo(item)" v-if="details"></v-list-item-subtitle>
                      </v-list-item-content>
                      
                      <v-list-item-action v-if="item.type_movement == 'debit'" v-text="formatNumberMask(item.amount)" class="red--text"></v-list-item-action>
                      <v-list-item-action v-if="item.type_movement == 'credit'" v-text="formatNumberMask(item.amount)"></v-list-item-action>
                      <v-list-item-icon>
                        <v-icon @click="filterValue(item)">mdi-filter</v-icon>
                      </v-list-item-icon>
                  </v-list-item>
                </v-list-item-group>
                
                <!-- <v-data-table
                  :headers="headers"
                  :search="searchFinancialBankStatement"
                  :items="listFinancialBankStatement"
                  loading-text="Carregando..."
                  progress
                  class="elevation-1"
                >
                  <template v-slot:[`item.included.movement_id.attributes.id`]="{ item }">
                    <v-icon v-if="item.included.movement_id!=null" small class="mr-2" @click="callMovement(item)">
                      mdi-check-bold
                    </v-icon>
                  </template>
                  <template v-slot:[`item.date_movement`]="{ item }">
                    {{ formatDate(item.date_movement) }}
                  </template>
                  <template v-slot:[`item.amount`]="{ item }">
                    <span class="red--text" v-if="item.type_movement == 'debit'">{{ formatNumberMask(item.amount) }}</span>
                    <span v-if="item.type_movement == 'credit'">{{ formatNumberMask(item.amount) }}</span>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="callEditItem(item)">
                      mdi-pencil
                    </v-icon>
                    <v-icon small class="mr-2" @click="callDeleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table> -->
                <div style="padding: 20px;">
                  <v-icon v-show="this.lenListFinancialBankStatement == this.lenListFinancialMovement" class="green--text">mdi-check-bold</v-icon> 
                  <v-icon v-show="this.lenListFinancialBankStatement != this.lenListFinancialMovement" class="gray--text">mdi-alert-outline</v-icon> 
                  &nbsp;
                  Quantidade de registros: {{ this.lenListFinancialBankStatement }} <br>
                  <v-icon v-show="this.valueCreditListFinancialBankStatement == this.valueCreditListFinancialMovement" class="green--text">mdi-check-bold</v-icon> 
                  <v-icon v-show="this.valueCreditListFinancialBankStatement != this.valueCreditListFinancialMovement" class="gray--text">mdi-alert-outline</v-icon> 
                  &nbsp;
                  Total crédito: {{ formatNumberMask(this.valueCreditListFinancialBankStatement) }} <br>
                  <v-icon v-show="this.valueDebitListFinancialBankStatement == this.valueDebitListFinancialMovement" class="green--text">mdi-check-bold</v-icon> 
                  <v-icon v-show="this.valueDebitListFinancialBankStatement != this.valueDebitListFinancialMovement" class="gray--text">mdi-alert-outline</v-icon> 
                  &nbsp;
                  Total débito: {{ formatNumberMask(this.valueDebitListFinancialBankStatement) }} <br>
                  <v-icon v-show="this.valueListFinancialBankStatement == this.valueListFinancialMovement" class="green--text">mdi-check-bold</v-icon> 
                  <v-icon v-show="this.valueListFinancialBankStatement != this.valueListFinancialMovement" class="gray--text">mdi-alert-outline</v-icon> 
                  &nbsp;
                  Saldo: {{ formatNumberMask(this.valueListFinancialBankStatement) }} <br>
                </div>

              </v-col>

              <v-col cols="12" sm="6">
                
                <v-card-title>
                  Movimentos
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="!loadingFM"
                    class="mr-2"
                    type="button"
                    color="blue"
                    icon
                    @click="toggleSearch()"
                    :color="(showSearch)?'blue':'gray'"
                  >
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="!loadingFM"
                    class="mr-2"
                    type="button"
                    color="blue"
                    icon
                    @click="refreshData()"
                  >
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                  <!-- <v-btn
                    class="mr-2"
                    type="button"
                    color="blue"
                    icon
                    @click="openFinancialBankStatementForm()"
                  >
                    <v-icon>mdi-plus-circle</v-icon>
                  </v-btn> -->
                </v-card-title>
    
                <div
                  class="d-flex justify-space-between align-center"
                  v-if="showSearch"
                >
                  <v-col cols="9">
                    <v-text-field
                      v-model="searchFinancialMovement"
                      append-icon="mdi-magnify"
                      label="Pesquisar"
                      dense
                      single-line
                      autofocus
                      hide-details
                    />
                  </v-col>
    
                  <v-col cols="3" class="px-4">
                    <v-row justify="end" class="mr-2">
                      <v-btn @click="clearSearchFM()">
                        <v-icon color="grey"> mdi-broom </v-icon>
                        Limpar
                      </v-btn>
                      <!-- <v-btn icon @click="toggleSearch()">
                        <v-icon color="grey"> mdi-close </v-icon>
                      </v-btn> -->
                    </v-row>
                  </v-col>
                </div>
                
                <v-list-item-group
                  v-model="positionFM"
                  color="primary"
                >
                  <v-list-item
                    v-for="(item, i) in this.filterFM"
                    :key="item.id"
                    three-line
                    @click="getMovement(item)"
                  >
                    <!-- icone -->
                    <v-list-item-icon style="margin-right: 6px;">
                      <v-icon small class="mr-2">
                        {{ checkFinancialBankStatement(item) }}
                      </v-icon>  
                    </v-list-item-icon>
                    <!-- conteudo -->
                    <v-list-item-content style="border-bottom: 1px dotted #ccc; align-content:flex-start;" :class=" details ? 'details-on' : 'details-off'">
                      <!-- data do movimento -->
                      <v-list-item-title style="height:20px; margin: 4px 0px 12px 0px;" v-text="formatDate(item.movement) + formatRecurrence(item.recurrence)"></v-list-item-title>
                      <!-- nota -->
                      <v-list-item-subtitle v-text="item.note" v-if="details"></v-list-item-subtitle>
                      <!-- entidade -->
                      <v-list-item-subtitle v-text="item.included.entity_id?.attributes.title" v-if="details"></v-list-item-subtitle>
                      <!-- categoria -->
                      <v-list-item-subtitle v-text="item.included.category_id?.attributes.title" v-if="details"></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action v-if="item.type_movement == 'D'" v-text="formatNumberMask(item.amount)" class="red--text"></v-list-item-action>
                    <v-list-item-action v-if="item.type_movement == 'C'" v-text="formatNumberMask(item.amount)"></v-list-item-action>
                    <v-list-item-icon>
                        <v-icon v-if="item.situation=='R'" style="color:#cccccc">mdi-invoice-text-check-outline</v-icon>
                        <v-icon v-if="item.situation=='O'" class="red--text">mdi-invoice-text-clock-outline</v-icon>
                        <v-icon @click="callDeleteItem(item)">mdi-delete</v-icon>
                        <v-icon v-if="item.order==1" style="color:#dadada">mdi-arrow-collapse-up</v-icon>
                        <v-icon v-if="item.order>1" @click="callOrderChange(item)">mdi-arrow-up</v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                </v-list-item-group>
               
                <div v-if="positionFBS!=null&&positionFM!=null">
                  <v-btn
                  color="success"
                  class="white--text my-5 mr-3"
                  @click="callUpdateFBS()"
                  >
                  <v-icon> mdi-link-variant-plus </v-icon>
                  Vincular registros
                  </v-btn>
                  <div v-if="this.financialMovement.amount!=this.financialBankStatement.amount">
                    <v-alert dense icon="mdi-alert" type="warning">
                      O Valor do extrato é diferente do valor do movimento
                    </v-alert>
                  </div>
                  <div v-if="this.financialMovement.movement!=this.financialBankStatement.date_movement">
                    <v-alert dense icon="mdi-alert" type="warning">
                      A data do extrato é diferente da data do movimento
                    </v-alert>
                  </div>
                </div>

                <div style="padding: 20px;">
                  <v-icon v-show="this.lenListFinancialBankStatement == this.lenListFinancialMovement" class="green--text">mdi-check-bold</v-icon> 
                  <v-icon v-show="this.lenListFinancialBankStatement != this.lenListFinancialMovement" class="gray--text">mdi-alert-outline</v-icon> 
                  &nbsp;
                  Quantidade de registros: {{ this.lenListFinancialMovement }} <br>
                  <v-icon v-show="this.valueCreditListFinancialBankStatement == this.valueCreditListFinancialMovement" class="green--text">mdi-check-bold</v-icon> 
                  <v-icon v-show="this.valueCreditListFinancialBankStatement != this.valueCreditListFinancialMovement" class="gray--text">mdi-alert-outline</v-icon> 
                  &nbsp;
                  Total crédito: {{ formatNumberMask(this.valueCreditListFinancialMovement) }} <br>
                  <v-icon v-show="this.valueDebitListFinancialBankStatement == this.valueDebitListFinancialMovement" class="green--text">mdi-check-bold</v-icon> 
                  <v-icon v-show="this.valueDebitListFinancialBankStatement != this.valueDebitListFinancialMovement" class="gray--text">mdi-alert-outline</v-icon> 
                  &nbsp;
                  Total débito: {{ formatNumberMask(this.valueDebitListFinancialMovement) }} <br>
                  <v-icon v-show="this.valueListFinancialBankStatement == this.valueListFinancialMovement" class="green--text">mdi-check-bold</v-icon> 
                  <v-icon v-show="this.valueListFinancialBankStatement != this.valueListFinancialMovement" class="gray--text">mdi-alert-outline</v-icon> 
                  &nbsp;
                  Saldo: {{ formatNumberMask(this.valueListFinancialMovement) }} <br>
                </div>

              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <!-- <FinancialBankStatementForm
      :formData="financialMovement"
      :showForm="showFinancialBankStatementForm"
      @emitClose="closeFinancialBankStatementForm"
    /> -->

    <ConfirmDialog
      :title="deleteText"
      :dialog="toggleDialogDeleteFinancialMovement"
      @confirm="deleteItemConfirm"
      @cancel="closeDialogConfirmDeleteFinancialMovement"
    />

  </div>
</template>
  
  <script>

import moment from "moment";

import { FinancialBankStatementManager } from "@/manager/financial-bank-statement-manager";
const financialBankStatementManager = new FinancialBankStatementManager();

import { FinancialBankStatementProcessManager } from "@/manager/financial-bank-statement-process-manager";
const financialBankStatementProcessManager = new FinancialBankStatementProcessManager();

import { FinancialAccountManager } from "@/manager/financial-account-manager";
const financialAccountManager = new FinancialAccountManager();

import { FinancialMovementManager } from "@/manager/financial-movement-list-manager";
const financialMovementManager = new FinancialMovementManager();

// import FinancialBankStatementForm from "./FinancialBankStatementForm.vue";

import ConfirmDialog from "@/components/Dialogs/ConfirmDialog.vue";

export default {
  name: "FinancialBankStatement",
  components: {
    // FinancialBankStatementForm,
    ConfirmDialog,
  },

  created() {
    this.refreshData();
  },

  computed: {
    loadingFBS: () => financialBankStatementManager.state.loading,
    loadingFBSP: () => financialBankStatementProcessManager.state.loading,
    loadingFA: () => financialAccountManager.state.loading,
    loadingFM: () => financialMovementManager.state.loading,

    filterFBS() {

      if(this.listFinancialBankStatement.length==0){
        console.warn('this.listFinancialMovement vazia');
      }

      this.valueDebitListFinancialBankStatement = 0;
      this.valueCreditListFinancialBankStatement = 0;
      this.valueListFinancialBankStatement = 0;
      this.lenListFinancialBankStatement = 0;
      
      return this.listFinancialBankStatement.filter(item => {
        let ret = false;
        this.searchFinancialBankStatement = this.searchFinancialBankStatement +'';
        let d = item.date_movement.toString().indexOf(this.searchFinancialBankStatement.toString()) > -1; 
        let m = item?.memo?.toLowerCase().indexOf(this.searchFinancialBankStatement.toString().toLowerCase()) > -1; 
        let a = this.formatNumberMask(item.amount).toString().indexOf(this.searchFinancialBankStatement) > -1; 
        let an= item.amount.toString().indexOf(this.searchFinancialBankStatement) > -1; 
        
        if (a || d || m || an) {
          ret = true;
        }
        
        if(this.dateFilter!=''){
          if(item.date_movement!=this.dateFilter){
            ret = false;
          }
        }

        if (ret){
          if(item.type_movement=='debit'){
            this.valueDebitListFinancialBankStatement += item.amount* (-1);
          }
          if(item.type_movement=='credit'){
            this.valueCreditListFinancialBankStatement += item.amount;
          }
          this.lenListFinancialBankStatement ++;
          this.valueListFinancialBankStatement += item.amount;
        }

        return ret;
      })
    },
    
    filterFM() {

      if(this.listFinancialMovement.length==0){
        console.warn('this.listFinancialMovement vazia');
      }
      
      this.valueDebitListFinancialMovement = 0;
      this.valueCreditListFinancialMovement = 0;
      this.valueListFinancialMovement = 0;
      this.lenListFinancialMovement = 0;
      
      return this.listFinancialMovement.filter(item => {
        let ret = false;
        this.searchFinancialMovement = this.searchFinancialMovement +'';
        let d = item.movement.toString().indexOf(this.searchFinancialMovement.toString()) > -1; 
        let n = item?.note?.toLowerCase().indexOf(this.searchFinancialMovement.toString().toLowerCase()) > -1; 
        let c = item.included.category_id?.attributes.title.toLowerCase().indexOf(this.searchFinancialMovement.toString().toLowerCase()) > -1; 
        let e = item.included.entity_id?.attributes.title.toLowerCase().indexOf(this.searchFinancialMovement.toString().toLowerCase()) > -1; 
        let a = this.formatNumberMask(item.amount).toString().indexOf(this.searchFinancialMovement) > -1; 
        let an = item.amount.toString().indexOf(this.searchFinancialMovement) > -1; 
        
        if (d || n || c || e || a || an) {
          ret = true;
        }

        if(this.dateFilter!=''){
          if(item.movement!=this.dateFilter){
            ret = false;
          }
        }

        if (ret){
          if(item.type_movement=='D'){
            this.valueDebitListFinancialMovement += item.amount * (-1);
          }
          if(item.type_movement=='C'){
            this.valueCreditListFinancialMovement += item.amount;
          }
          this.lenListFinancialMovement ++;
          this.valueListFinancialMovement += item.amount;
        }

        return ret;
      })
    },

  },

  watch: {
    financialBankStatementProcess(pValue) {
      // console.log('pValue');
      // console.log(pValue);
      // console.log(pValue.date_start);
      this.dateFilter = pValue.date_start;
      // console.log(pValue.date_end);
      this.possibleDates = [];
      let i = -1;
      let c = '';
      let dEnd = c = moment(pValue.date_end).format("YYYY-MM-DD");  
      do {
        i += 1;
        c = moment(pValue.date_start).add(i, "days").format("YYYY-MM-DD");  
        this.possibleDates.push(c);
      } while (i < 31 && dEnd != c);
      // console.log('possibleDates');
      // console.log(this.possibleDates);
      setTimeout(() => {
        this.dateFilter = '';
      }, 600); 
    },
    listFinancialBankStatement(){
      this.calcListFinancialBankStatement();
    },
    listFinancialMovement(){
      this.calcListFinancialMovement();
    }
  },

  methods: {
    // TARGETS
    async callList(pItem) {
      this.dateFilter='';
      console.warn(pItem);
      this.financialBankStatementProcess = pItem;
      var account_id = pItem.included.file.attributes.model_id;
      var filterData = { 'id': pItem.id, 'account_id' : account_id };
      const data = await financialBankStatementManager.listFilter(filterData);
      // console.log(data);
      this.listFinancialBankStatement = data;
    },
    async callListFinancialBankStatementProcess(pItem) {
      // console.log(pItem);
      var account_id = pItem.id;
      var filterData = { 'account_id' : account_id };
      const data = await financialBankStatementProcessManager.listFilterWithFiles(filterData);
      // console.log(data);
      this.accountSelected = pItem.title;
      this.listFinancialBankStatementProcess = data;
      this.listFinancialMovement = [];
      this.listFinancialBankStatement = [];
      this.positionFBS=null;
      this.positionFBSP=null;
      this.positionFM=null;
      this.changeShowAccount(false);
    },
    async callListAccount() {
      const data = await financialAccountManager.list();
      // console.log(data);
      this.listFinancialAccount = data;
    },

    async callMovement(pItem){
      // console.log(pItem);
      // console.log(pItem.included.file.attributes.model_id);
      var account_id = pItem.included.file.attributes.model_id;
      var date_start = pItem.date_start;
      var date_end = pItem.date_end;
      var filterData = { 'account_id' : account_id , 'movement_from' : date_start, 'movement_to' : date_end};
      const data = await financialMovementManager.listFilter(filterData);
      console.log(data);
      this.listFinancialMovement = data;
      
    },

    calcListFinancialBankStatement(){
      // this.valueDebitListFinancialBankStatement = 0;
      // this.valueCreditListFinancialBankStatement = 0;
      // this.valueListFinancialBankStatement = 0;
      // this.listFinancialBankStatement.forEach((item) => {
      //   if(item.type_movement=='debit'){
      //     this.valueDebitListFinancialBankStatement += item.amount* (-1);
      //   }
      //   if(item.type_movement=='credit'){
      //     this.valueCreditListFinancialBankStatement += item.amount;
      //   }
      //   this.valueListFinancialBankStatement += item.amount;
      // });
    },

    calcListFinancialMovement(){
      // this.valueDebitListFinancialMovement = 0;
      // this.valueCreditListFinancialMovement = 0;
      // this.valueListFinancialMovement = 0;
      // this.listFinancialMovement.forEach((item) => {
      //   if(item.type_movement=='D'){
      //     this.valueDebitListFinancialMovement += item.amount * (-1);
      //   }
      //   if(item.type_movement=='C'){
      //     this.valueCreditListFinancialMovement += item.amount;
      //   }
      //   this.valueListFinancialMovement += item.amount;
      // });
    },

    changeDate(pDate) {
      console.log(pDate);
      // // this.$set(this.done, 0, true)
      // // alert(`You have just double clicked the following date: ${date}`)
      // var date = moment(pDate, "YYYY-MM-DD").startOf('day');
      // if (moment(date).isValid()) {
      //   this.movementDateBr = moment(date).format('DD/MM/YYYY');
      //   this.movementModel.movement = moment(date).format('YYYY-MM-DD');
      //   this.movementModel.posting = this.movementModel.movement;
      //   this.setReference();
      //   this.setSituation(pDate);
      //   this.amountFocus();
      // }
      // this.$forceUpdate();
    },

    // ABRE FORMULARIO DE INCLUSÃO
    // openFinancialBankStatementForm() {
    //   this.financialMovement = {};
    //   this.showFinancialBankStatementForm = true;
    // },

    // FECHAR MODAL INCLUSAO
    // closeFinancialBankStatementForm(pHasUpdate) {
    //   if (pHasUpdate) {
    //     this.refreshData();
    //   }
    //   this.financialMovement = {};
    //   this.showFinancialBankStatementForm = false;
    // },

    // EDITAR DOCUMENTO
    // callEditItem(pItem) {
    //   // console.log(pItem)
    //   // para evitar que as atualizações afetem o objeto original, Object.assign() pode ser uma ferramenta útil.
    //   this.financialMovement = Object.assign({}, pItem);
    //   this.showFinancialBankStatementForm = true;
    // },

    async deleteItemConfirm() {
      const ret = await financialMovementManager.delete(this.financialMovement);
      if (ret.ok) {
        this.closeDialogConfirmDeleteFinancialMovement();
        this.callMovement(this.financialBankStatementProcess);
      }
    },

    // ATUALIZA UM ITEM
    async callUpdateFBS() {
      if (!this.financialBankStatement||!this.financialMovement){
        return;
      };
      this.financialBankStatement.movement_id = this.financialMovement.id
      // console.log(this.financialBankStatement);
      var response = await financialBankStatementManager.update(this.financialBankStatement);
      if (response.ok) {
        this.callList(this.financialBankStatementProcess);
        this.positionFBS=null;
        this.positionFM =null;
        // this.clearSearchFM();
      }
    },

    callDeleteItem(pItem) {
      // console.log(pItem);
      this.financialMovement = pItem;
      if(this.financialMovement.transfer==null){
        this.deleteText = "Tem certeza de deseja excluir o movimento: " + pItem.movement + " no valor de R$ " + pItem.amount + " ?";
      } else {
        this.deleteText = "Tem certeza de deseja excluir a transferência: " + pItem.movement + " no valor de R$ " + pItem.amount + " ?";
      }
      this.toggleDialogDeleteFinancialMovement = true;
    },

    async callOrderChange(pItem){
      const ret = await financialMovementManager.orderChange(pItem);
      // console.log(ret);
      if (ret.ok) {
        this.callMovement(this.financialBankStatementProcess);
      }
    },

    closeDialogConfirmDeleteFinancialMovement() {
      this.financialMovement = {};
      this.positionFM = null;
      this.toggleDialogDeleteFinancialMovement = false;
    },

    // UTILS
    refreshData() {
      this.callListAccount();
    },

    toggleSearch() {
      this.showSearch = !this.showSearch;
    },

    toggleDetails(){
      this.details = !this.details;
    },

    toggleFilterCheck(){
      this.filterCheck = !this.filterCheck;
    },

    clearSearchFBS() {
      this.searchFinancialBankStatement = " ";
    },

    clearSearchFM() {
      this.searchFinancialMovement = " ";
    },

    formatDate(pValue) {
      if(pValue==''){return};
      var vValue = String(pValue);
      vValue = moment(vValue, 'YYYY-MM-DD').format("DD/MM/YYYY");
      return vValue;
    },

    formatRecurrence(pValue){
      if(pValue==null){return ''};
      return ' - Parcela: ' + pValue;
    },

    checkFinancialBankStatement(pItem){
      if(this.listFinancialBankStatement.length==0){
        return '';
      }
      let check = this.listFinancialBankStatement.filter(item => {
        // console.log('--> '+ item.movement_id);
        let m = item.movement_id == pItem.id; 
        return (m) ? true : false;
      });
      return (check.length==1) ? "mdi-check-bold" : "mdi-clock-outline";
    },

    formatNumberMask(pValue) {
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
      var vValue = new Intl.NumberFormat(
          'pt-BR', 
          { style: 'decimal', 
            currency: 'BRL', 
            minimumFractionDigits : 2, 
            maximumFractionDigits: 2 
          }).format(pValue);
      return vValue;
    },

    getInfo(pItem){
      let vInfo = '';
      if(pItem.title!=null){
        vInfo = vInfo + pItem.title
      }
      if(pItem.memo!=null){
        if(vInfo==''){
          vInfo = vInfo + pItem.memo
        } else {
          vInfo = vInfo +'<br>'+ pItem.memo
        } 
      }
      return vInfo;
    },
    
    getMovement(pItem){
      // console.log(pItem);
      this.financialMovement = pItem;
    },
    
    getFinancialBankStatement(pItem){
      // console.log(pItem);
      this.financialBankStatement = pItem;
    },

    filterValue(pItem){
      // console.log(pItem);
      // console.log(pItem.amount);
      this.searchFinancialMovement = pItem.amount; 
    },

    changeShowAccount(pValue){
      this.showAccounts = pValue;
    },

  },
  data() {
    return {

      positionFA:null,
      positionFBS:null,
      positionFBSP:null,
      positionFM:null,
      positionPD:null,

      details: true,
      
      listFinancialAccount: [],
      accountSelected:"",
      showAccounts:true,
      listFinancialMovement: [],
      valueListFinancialMovement: 0,
      lenListFinancialMovement: 0,
      valueCreditListFinancialMovement: 0,
      valueDebitListFinancialMovement: 0,
      financialMovement: {},
      account : [],

      // TARGETS
      listFinancialBankStatement: [],
      valueListFinancialBankStatement: 0,
      valueCreditListFinancialBankStatement: 0,
      valueDebitListFinancialBankStatement: 0,
      lenListFinancialBankStatement: 0,
      financialBankStatement: {},
      listFinancialBankStatementProcess: [],
      possibleDates:[],
      dateFilter:'',
      financialBankStatementProcess: {},
      // showFinancialBankStatementForm: false,
      filterCheck:true,

      // UTILS
      deleteText: "",
      toggleDialogDeleteFinancialMovement: false,

      // DEFINIÇÕES DA TABELA
      showSearch: false,
      searchFinancialBankStatement: "",
      searchFinancialMovement: "",
      headers: [
        {
          text: "Lançado",
          align: "start",
          sortable: false,
          value: "included.movement_id.attributes.id",
        },
        {
          text: "Movimento",
          align: "start",
          value: "date_movement",
          sortable: true,
        },
        {
          text: "Título",
          align: "start",
          sortable: false,
          value: "title",
        },
        {
          text: "Obsevações",
          align: "start",
          sortable: false,
          value: "memo",
        },
        {
          text: "Categoria",
          align: "start",
          sortable: false,
          value: "category",
        },
        {
          text: "Valor",
          align: "end",
          sortable: false,
          value: "amount",
        },
        { text: "Ações", value: "actions", sortable: false, align: "center" },
      ]
    };
  },
};
</script>
  
<style scoped>
.details-on{
  height: 140px;
}
.details-onoff{
  height: 80px;
}
</style>
  