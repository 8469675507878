<template>
  <div class="pa-4">
    <v-row>
      <div class="text-h5 text-sm-h4 ml-2">Contas financeiras</div>
      <v-spacer></v-spacer>
    </v-row>

    <v-row class="mt-6">

      <v-col cols="12" sm="6">
        <v-card :loading="loading" style="z-index: 0">
          <div>
            <v-card-title>
              Lista de contas financeiras
              <v-spacer></v-spacer>
              <v-btn v-if="!loading" class="mr-2" type="button" color="blue" icon @click="toggleSearch()">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
              <v-btn v-if="!loading" class="mr-2" type="button" color="blue" icon @click="refreshData()">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
              <v-btn class="mr-2" type="button" color="blue" icon @click="openFinancialAccountForm()">
                <v-icon>mdi-plus-circle</v-icon>
              </v-btn>
            </v-card-title>

            <div class="d-flex justify-space-between align-center" v-if="showSearch">
              <v-col cols="9">
                <v-text-field v-model="searchFinancialAccount" append-icon="mdi-magnify" label="Pesquisar" dense
                  single-line autofocus hide-details />
              </v-col>

              <v-col cols="3" class="px-4">
                <v-row justify="end" class="mr-2">
                  <v-btn icon @click="clearSearch()">
                    <v-icon color="grey"> mdi-broom </v-icon>
                  </v-btn>
                  <v-btn icon @click="toggleSearch()">
                    <v-icon color="grey"> mdi-close </v-icon>
                  </v-btn>
                </v-row>
              </v-col>
            </div>

            <v-data-table :headers="headers" :search="searchFinancialAccount" :items="listFinancialAccount"
              loading-text="Carregando..." progress class="elevation-1">
              <template v-slot:[`item.type`]="{ item }">
                {{ getTypeDescription(item.type) }}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small class="mr-2" @click="callEditItem(item)">
                  mdi-pencil
                </v-icon>
                <v-icon small class="mr-2" @click="callDeleteItem(item)">
                  mdi-delete
                </v-icon>
                <v-icon small class="mr-2" @click="selectAccount(item)"> mdi-file </v-icon>
              </template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6">

        <v-card :loading="loading" class="pa-4 mb-6">
          <v-card-title>Upload</v-card-title>
          <v-card-text>
            <div v-if="pAccountId==''">
              Escolha uma conta
            </div>
            <div v-else>
              Conta: <b>{{ this.financialAccount.title }}</b>
              <br />
              <br />

              <Upload :url="urlUpload" @emitSuccessUploadFiles="successUploadFiles()">
              </Upload>

            </div>
          </v-card-text>
        </v-card>

        <v-card :loading="loading" class="pa-4 mb-6">
          <v-card-title>Adicionar arquivos a fila de processamento</v-card-title>
          <v-card-text>
            <div v-if="pAccountId == ''">
              Escolha uma conta
            </div>
            <div v-else>
              <div v-if="listFinancialOrphanFiles.length == 0">
                Não há arquivos para adicionar a fila
              </div>
              <div v-else>
                <v-combobox v-model="selectedLayout" :items="listLayouts" item-text="title" item-value="id"
                  label="Selecione um layout"></v-combobox>
                <v-text-field v-model="titleProcess" autofocus label="Título" maxlength="30" required></v-text-field>
                <v-list>
                  <v-list-item v-for="(item, index) in listFinancialOrphanFiles">
                    <v-list-item-title>
                      <v-icon @click="addToProcess(item.id)">mdi-tray-plus</v-icon> &nbsp
                      {{ item.name }}
                    </v-list-item-title>
                    <v-spacer></v-spacer>
                    <span v-if="item.included.process?.processed==null">
                      <v-icon size="20" class="mr-2" @click="deleteFile(item)"> mdi-delete </v-icon>
                    </span>
                  </v-list-item>
                </v-list>
              </div>
            </div>
          </v-card-text>
        </v-card>

        <v-card :loading="loading" class="pa-4 mb-6">
          <v-card-title>Fila de processamento</v-card-title>
          <v-card-text>
            <div v-if="pAccountId == ''">
              Escolha uma conta
            </div>
            <div v-else>
              <div v-if="listFinancialFilesToProcess.length == 0">
                Não há arquivos para processar
              </div>
              <div v-else>
                <v-list>
                  <v-list-item v-for="(item, index) in listFinancialFilesToProcess">
                    <v-list-item-title>
                      <v-icon @click="getProcessFile(item.id, pAccountId)">mdi-file-cog-outline</v-icon> &nbsp
                      {{ item.included.file.attributes.name }}
                    </v-list-item-title>
                    <v-spacer></v-spacer>
                    <v-icon size="20" class="mr-2" @click="removeFromProcess(item)"> mdi-link-off </v-icon>
                  </v-list-item>
                </v-list>
              </div>
            </div>
          </v-card-text>
        </v-card>

        <v-card :loading="loading" class="pa-4 mb-6">
          <v-card-title>Arquivos processados</v-card-title>
          <v-card-text>
            <div v-if="pAccountId == ''">
              Escolha uma conta
            </div>
            <div v-else>
              <div v-if="listFinancialFilesProcessed.length == 0">
                Não há arquivos processados
              </div>
              <div v-else>
                <v-list>
                  <v-list-item v-for="(item, index) in listFinancialFilesProcessed" two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                      <v-list-item-subtitle>{{ item.included.file.attributes.name }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-spacer></v-spacer>
                    <v-icon size="20" class="mr-2" @click="removeProcessedFiles(item)"> mdi-delete </v-icon>
                  </v-list-item>
                </v-list>
              </div>
            </div>
          </v-card-text>
        </v-card>

        <v-card :loading="loading" class="pa-4 mb-6">
          <v-card-title>Arquivos</v-card-title>
          <v-card-text>
            <div v-if="pAccountId == ''">
              Escolha uma conta
            </div>
            <div v-else>
              <div v-if="listFiles.length == 0">
                Não há arquivos
              </div>
              <div v-else>
                <v-list>
                  <v-list-item v-for="(item, index) in listFiles">
                    <v-list-item-title>
                      {{ item.name }}
                    </v-list-item-title>
                    <v-spacer></v-spacer>
                    <span>
                      <span v-if="item.included.process?.processed==null">
                        <v-icon size="20" class="mr-2" @click="deleteFile(item)"> mdi-delete </v-icon>
                      </span>
                    </span>
                  </v-list-item>
                </v-list>
              </div>
            </div>
          </v-card-text>
        </v-card>

      </v-col>

    </v-row>

    <FinancialAccountForm :formData="financialAccount" :showForm="showFinancialAccountForm"
      @emitClose="closeFinancialAccountForm" />

    <ConfirmDialog :title="confirmTitle" :dialog="toggleDialogDeleteFinancialAccount" @confirm="deleteItemConfirm"
      @cancel="closeDialogConfirmDeleteFinancialAccount" />
    
    <ConfirmeDialogArquivo :title="confirmTitle" :dialog="toggleDialogFinancialFileDelete"
      @confirm="deleteFileConfirm" @cancel="closeDialogConfirmDeleteFile" />
    
    <ConfirmeDialogProcess :title="confirmTitle"
      :dialog="toggleDialogProcess" @confirm="deleteProcessConfirm" @cancel="closeDialogConfirmDeleteProcess" />
    
    <ConfirmeDialogProcess :title="confirmTitle"
      :dialog="toggleDialogProcessed" @confirm="deleteProcessedConfirm" @cancel="closeDialogConfirmDeleteProcessed" />

  </div>
</template>
  
<script>

import { FinancialAccountManager } from "@/manager/financial-account-manager";
const financialAccountManager = new FinancialAccountManager();
import { FinancialBankStatementProcessManager } from "@/manager/financial-bank-statement-process-manager";
const financialBankStatementProcessManager = new FinancialBankStatementProcessManager();
import { FilesManager } from "@/manager/files-manager";
const filesManager = new FilesManager();

import FinancialAccountForm from "./FinancialAccountForm.vue";

import ConfirmDialog from "@/components/Dialogs/ConfirmDialog.vue";
import ConfirmeDialogArquivo from "@/components/Dialogs/ConfirmDialog.vue";
import ConfirmeDialogProcess from "@/components/Dialogs/ConfirmDialog.vue";

import Upload from "@/components/Upload";

export default {
  name: "FinancialAccount",
  components: {
    FinancialAccountForm,
    ConfirmDialog,
    ConfirmeDialogArquivo,
    ConfirmeDialogProcess,
    Upload
  },

  created() {
    this.refreshData();
  },

  computed: {
    loading: () => financialAccountManager.state.loading,
  },

  data() {
    return {

      // DIALOG
      confirmTitle: "",

      // LAYOUTS
      listLayouts: [],
      selectedLayout: "",

      types: [
        { key: 'MO', value:'Dinheiro'},
        { key: 'CC', value:'Cartão de crédito'},
        { key: 'AC', value:'Conta corrente'},
        { key: 'PP', value:'Pré pago'},
        { key: 'SA', value:'Poupança'},
      ],

      titleProcess: "",
      
      // ACCOUNT
      listFinancialAccount: [],
      financialAccount: {},
      pAccountId:'',
      pAccountTitle:'',
      showFinancialAccountForm: false,
      toggleDialogDeleteFinancialAccount: false,

      // ACCOUNT : TABLE
      showSearch: false,
      searchFinancialAccount: "",
      headers: [
        {
          text: "Conta",
          align: "start",
          value: "title",
          sortable: true,
        },
        {
          text: "Tipo",
          align: "start",
          sortable: false,
          value: "type",
        },
        { text: "Ações", value: "actions", sortable: false, align: "center" },
      ],

      // FILES
      listFiles: [],
      file: [],
      urlUpload: '',
      toggleDialogFinancialFileDelete: false,
      
      // ORPHAN FILES 
      listFinancialOrphanFiles: [],
      
      // FILES TO PROCESS
      listFinancialFilesToProcess: [],

      // FILES PROCESSED
      listFinancialFilesProcessed: [],
      
      // PROCESS
      toggleDialogProcess : false,
      financialBankStatementProcess: [],
      financial_bank_statement_processes: {
        financial_layout_id: "",
        file_id: "",
        title: "",
      },

      // PROCESSED
      toggleDialogProcessed : false,
      
    };
  },

  methods: {

    // UTILS
    refreshData() {
      this.callList();
      this.getLayouts();
    },
    
    // ACCOUNT
    async callList() {
      const data = await financialAccountManager.list();
      this.listFinancialAccount = data;
    },

    // ACCOUNT : ABRIR FORMULARIO
    openFinancialAccountForm() {
      this.financialAccount = {};
      this.showFinancialAccountForm = true;
    },
    
    // ACCOUNT : EDITAR ITEM
    callEditItem(pItem) {
      // console.log(pItem)
      // para evitar que as atualizações afetem o objeto original, Object.assign() pode ser uma ferramenta útil.
      this.financialAccount = Object.assign({}, pItem);
      this.showFinancialAccountForm = true;
    },

    // ACCOUNT : EXCLUIR
    callDeleteItem(pItem) {
      this.financialAccount = pItem;
      this.confirmTitle =
        "Tem certeza de deseja excluir o item: " + pItem.title + " ?";
      this.toggleDialogDeleteFinancialAccount = true;
    },

    // ACCOUNT : CONFIRMAR EXCLUSÃO
    async deleteItemConfirm() {
      const ret = await financialAccountManager.delete(this.financialAccount);
      if (ret.ok) {
        this.closeDialogConfirmDeleteFinancialAccount();
        this.refreshData();
      }
    },

    // ACCOUNT : CANCELAR EXCLUSÃO CONTA
    closeDialogConfirmDeleteFinancialAccount() {
      this.financialAccount = {};
      this.toggleDialogDeleteFinancialAccount = false;
    },

    // ACCOUNT : FECHAR MODAL INCLUSAO
    closeFinancialAccountForm(pHasUpdate) {
      if (pHasUpdate) {
        this.refreshData();
      }
      this.financialAccount = {};
      this.showFinancialAccountForm = false;
    },

    getTypeDescription(pType){
      let retValue = '';
      this.types.forEach(function(item) {
        if(item['key']==pType){
          retValue = item['value'];
        }
      });
      return retValue;
    },

    // ACCOUNT : TABLE
    toggleSearch() {
      this.showSearch = !this.showSearch;
    },
    clearSearch() {
      this.searchFinancialAccount = " ";
    },

    // ACCOUNT : ESCOLHER CONTA
    selectAccount(item) {
      console.log(item);
      this.financialAccount = item;
      this.pAccountId = item.id;
      this.pAccountTitle = item.title;
      this.urlUpload = '/files/financial-account/' + this.pAccountId;
      console.log(this.urlUpload);
      this.getFilesList(this.pAccountId);
      this.getOrphanFiles(this.pAccountId);
      this.getFilesListToProcess(this.pAccountId);
      this.getFilesListProcessed(this.pAccountId);
    },

    // FILES
    successUploadFiles() {
      this.getFilesList(this.pAccountId);
      this.getOrphanFiles(this.pAccountId);
    },
    async getFilesList(pAccountId) {
      console.log('getFilesList', pAccountId);
      const data = await financialAccountManager.getFilesList(pAccountId);
      console.log('listFiles', data)
      this.listFiles = data;
    },
    async getOrphanFiles(pAccountId) {
      console.log('getOrphanFiles', pAccountId);
      const data = await financialAccountManager.getOrphanFiles(pAccountId);
      // console.log(data);
      console.log('listFinancialOrphanFiles', data)
      this.listFinancialOrphanFiles = data;
    },
    deleteFile(item) {
      console.log(item);
      this.confirmTitle = `Tem certeza de deseja excluir o arquivo ${item.name}?`;
      this.file = item;
      this.toggleDialogFinancialFileDelete = true;
    },
    async deleteFileConfirm() {
      await filesManager.delete(this.file);
      this.getFilesList(this.pAccountId);
      this.getOrphanFiles(this.pAccountId);
      this.closeDialogConfirmDeleteFile();
    },
    closeDialogConfirmDeleteFile() {
      this.toggleDialogFinancialFileDelete = false;
    },

    // PROCESS
    async getFilesListToProcess(pAccountId) {
      const data = await financialAccountManager.getFilesProcess('to-do', pAccountId);
      console.log('getFilesListToProcess', data)
      this.listFinancialFilesToProcess = data;
    },
    async getFilesListProcessed(pAccountId) {
      const data = await financialAccountManager.getFilesProcess('processed', pAccountId);
      console.log('getFilesListProcessed', data)
      this.listFinancialFilesProcessed = data;
    },
    async addToProcess(id) {
      // adicionar o arquivo em financial-bank-statement-process
      // console.log(this.selectedLayout.id);
      console.log(id);
      this.financial_bank_statement_processes.financial_layout_id = this.selectedLayout.id;
      this.financial_bank_statement_processes.file_id = id;
      this.financial_bank_statement_processes.title = this.titleProcess;
      
      await financialAccountManager.addToProcess(this.financial_bank_statement_processes, this.pAccountId);
      
      this.titleProcess = '';
      this.financial_bank_statement_processes.title = '';

      this.getOrphanFiles(this.pAccountId);
      this.getFilesListToProcess(this.pAccountId);
    },
    removeFromProcess(item) {
      console.log(item);
      this.confirmTitle = `Tem certeza de deseja remover este item da fila de processamento ?`;
      this.financialBankStatementProcess = item;
      this.toggleDialogProcess = true;
    },
    async deleteProcessConfirm() {
      await financialBankStatementProcessManager.delete(this.financialBankStatementProcess);
      this.getOrphanFiles(this.pAccountId);
      this.getFilesListToProcess(this.pAccountId);
      this.getFilesListProcessed(this.pAccountId);
      this.closeDialogConfirmDeleteProcess();
    },
    closeDialogConfirmDeleteProcess() {
      this.toggleDialogProcess = false;
    },
    async getProcessFile(pProcessId, pAccountId) {
      console.log(pProcessId);
      console.log(pAccountId);
      const data = await financialAccountManager.getProcessFile(pProcessId, pAccountId);
      console.log('getProcessFile', data)
      this.getFilesListToProcess(pAccountId);
      this.getFilesListProcessed(pAccountId);
    },

    // PROCESSED
    removeProcessedFiles(item) {
      console.log(item);
      this.confirmTitle = `Tem certeza de deseja remover este extrato e seus respectivos lançamentos?`;
      this.financialBankStatementProcess = item;
      this.toggleDialogProcessed = true;
    },
    deleteProcessedConfirm(){
      this.deleteProcessConfirm();
      this.toggleDialogProcessed = false;
    },
    closeDialogConfirmDeleteProcessed() {
      this.toggleDialogProcessed = false;
    },

    // LAYOUTS
    async getLayouts() {
      const data = await financialAccountManager.getLayouts();
      console.log('listLayouts', data)
      this.listLayouts = data;
    },


  }
};
</script>
  
<style scoped></style>
  